<template>
  <div class="home z-0">
    <div class="mx-2 mt-4 mb-8 page-title-text relative">
      <h1 class="font-medium cursor-default text-2xl">
        Eventos em

        <div class="inline-flex items-center cursor-pointer" @click.stop.prevent="openDialogButton">
          <span v-if="currentCity" class="font-normal tracking-normal">{{ currentCity }}</span>
          <div v-else class="animate-pulse translate-y-1 h-6 bg-gray-100 rounded-full dark:bg-zinc-800 w-32"></div>
          <icon-chevron-down />
        </div>
      </h1>

      <sl-dialog ref="dialog" label="Selecione a cidade" class="dialog-header-actions">
        <sl-menu>
          <sl-menu-item v-for="city in cities" :key="`idx-${city.name}`"
            @click="selectCity(city)">
              {{ city.name }}/{{ city.uf }} <small>({{ city.count }})</small>
          </sl-menu-item>
        </sl-menu>
      </sl-dialog>
    </div>

    <div v-if="isLoading" class="grid sm:gap-2 sm:grid-cols-2 md:gap-4 md:grid-cols-2 lg:gap-8 lg:grid-cols-3">
      <div v-for="idx in 6" :key="`skel-${idx}`" role="status" class="space-y-4 mb-8 animate-pulse w-full">
        <div class="flex items-center justify-center w-full h-48 bg-gray-100 rounded dark:bg-zinc-800">
          <svg class="w-12 h-12 text-gray-200 dark:text-zinc-600" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
        </div>
        <div class="w-full">
          <div class="h-8 bg-gray-100 rounded-full dark:bg-zinc-800 w-full mb-4"></div>
          <div class="flex">
            <div class="h-4 bg-gray-100 rounded-full dark:bg-zinc-800 w-1/2 mb-2.5"></div>
            <div class="h-4 bg-gray-100 rounded-full dark:bg-zinc-800 w-1/2 mb-2.5"></div>
          </div>
          <!-- <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div> -->
          <!-- <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div> -->
        </div>
        <span class="sr-only">carregando...</span>
      </div>
    </div>

    <div v-else class="grid sm:gap-2 sm:grid-cols-2 md:gap-4 md:grid-cols-2 lg:gap-8 lg:grid-cols-3">
      <NuxtLink class="w-full" :to="`/u/${event.slug}`" v-for="event,idx in healthEvents" :key="idx">
        <EventCardFull :event="event" :isLoading="isLoading" />
      </NuxtLink>
    </div>

    <PixtaApiError v-if="isApiOnError" />
  </div>
</template>

<script setup>

import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/user.js'

const userStore = useUserStore()
const { loggedIn: isLoggedIn } = storeToRefs(userStore)

const currentCity = ref();

const events = ref([]);
const isSeeOldEvents = ref(true);
const isCitySelectorModalOpen = ref(false);
const isApiOnError = ref(false);

const route = useRoute()
const router = useRouter()

const runtimeConfig = useRuntimeConfig()
const { baseApiUrl: baseUrl } = runtimeConfig.public

const cities = computed(() => {
  try {
    const filteredCities = currentHealth.value.cities.filter((c) => c.name !== '');
    return filteredCities;
  } catch (_) {
    return [];
  }
});

const citiesOld = computed(() => {
  try {
    const filteredCities = currentHealth.value.cities_old.filter((c) => c.name !== '');
    return filteredCities;
  } catch (_) {
    return ['Curitiba'];
  }
});

const healthEvents = computed(() => {
  try {
    return currentHealth.value.events;
  } catch (_) {
    return [];
  }
});

const currentEvents = computed(() => {
  try {
    if (isSeeOldEvents.value) {
      return events.value;
    } else {
      return events.value.filter((e) => Date.parse(e.event_starts_at) > new Date());
    }
  } catch (_) {
    return [];
  }
});

const shuffledEvents = computed(() => {
  try {
    return healthEvents.value
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  } catch (_) {
    return currentEvents.value;
  }
});

const { pending: isLoading, refresh, data: currentHealth } = await useFetch(`${baseUrl}/api/health.json`, {
  key: `${baseUrl}/api/health.json`,
  server: false, // TODO FIXME WIP
  transform: (data) => {
    try {
      currentCity.value = data.location?.city?.name
    } catch(_) {

    }
    return data
  }
})
// , { server: true }
// console.log(pending, dataRes.value)
// isLoading.value = pending.value

// async function serverPrefetch() {
//   // component is rendered as part of the initial request
//   // pre-fetch data on server as it is faster than on the client
//   // this.data = await fetchOnServer(/* ... */)
//   await health();
// }

watch(route, () => {
  isCitySelectorModalOpen.value = false;
});

const selectCity = async (city) => {
  // await userStore.changeCity(cityName);
  router.push(`/eventos/${city.slug}`)
  isCitySelectorModalOpen.value = false;


  // refresh()
}


const dialog = ref(null)
const openDialogButton = () => {
  dialog.value.show()
}


useHead(() => {
  const pageTitle = currentCity && currentCity.value ? `Eventos em ${currentCity.value}` : `Ingressos para seu evento favorito`,
        pageDescription = `Festa cheia, sem stress. Ingressos para seu evento favorito na pixta.me`,
        pageImage = `https://pixta.me/og.jpeg`
  return {
    title: pageTitle,
    description: pageDescription,
    meta: [
      { property: 'og:image', content: pageImage },
      { property: 'og:image:secure_url', content: pageImage },
      { property: 'og:image:type', content: 'image/jpeg' },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '525' },
      { property: 'og:image:alt', content: pageTitle },
      { property: 'og:title', content: pageTitle },
      { property: 'og:site_name', content: 'PIXTA.me' },
      { property: 'og:url', content: `https://pixta.me` },
      { property: 'og:description', content: pageDescription },
      { property: 'og:type', content: 'website' },
      { property: 'fb:app_id', content: '1751665528367178' },
      { property: 'og:locale', content: 'pt_BR' },
      { itemprop: 'name', content: 'PIXTA.me' },
      { itemprop: 'description', content: pageDescription },
      { itemprop: 'image', content: pageImage },
      { name: 'description', content: pageDescription },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: 'pixta_me' },
      { name: 'twitter:title', content: pageTitle },
      { name: 'twitter:description', content: pageDescription },
      { name: 'twitter:image', content: pageImage },
    ]
  }
})

const pageImage = computed(() => `https://pixta.me/og.jpeg`);

</script>
